export const theme = {
  app_title: "Wagyu Live",
  favicon_url: "assets/brand/wagyu_favicon_light.svg",
  favicon_url_dark_mode: "assets/brand/wagyu_favicon_dark.svg",
  header_logo_large_url: "assets/brand/wagyu_large_icon.svg",
  header_logo_large_url_alt: "Wagyu Live Logo",
  header_logo_small_url: "assets/brand/wagyu_small_icon.svg",
  home_show_hero_section: true,
  main_banner_url_srcset: [
    "assets/images/header/wagyu_header.png 577w"
  ],
  main_banner_url: "assets/images/header/wagyu_header.png",
  auction_listing_banner_url_srcset: [
    "assets/images/header/wagyu_header.png 577w"
  ],
  auction_listing_banner_url: "assets/images/header/wagyu_header.png",
  header_menu: [
    {
      title: "Auctions",
      router_link: ["/auctions"],
      menu_items: []
    },
    {
      title: "Sellers",
      router_link: ["/sellers"],
      menu_items: [
        {
          title: "Seller Portal",
          router_link: ['/sellers', 'seller-portal'],
          required_roles: ['SELLER']
        },
        {
          title: "Marketing and Sales on Wagyu Live",
          router_link: ['/sellers'],
          fragment: "seller-info"
        },
        {
          title: "Services",
          router_link: ['/sellers'],
          fragment: "seller-services"
        },
        {
          title: "Terms and Conditions",
          router_link: ['/sellers'],
          fragment: "terms-and-conditions"
        }
      ]
    },
    {
      title: "Buyers",
      router_link: ["/buyers"],
      menu_items: [
        {
          title: "Bidding Portal",
          router_link: ['/buyers', 'bidding-portal'],
          required_roles: ['SELLER']
        },
        {
          title: "Buying on Wagyu Live",
          router_link: ['/buyers']
        },
        {
          title: "Delivery & Payment",
          router_link: ['/buyers'],
          fragment: "delivery-payment"
        },
        {
          title: "Service Providers",
          router_link: ['/buyers'],
          fragment: "service-providers"
        },
        {
          title: "Terms and Conditions",
          router_link: ['/buyers'],
          fragment: "Terms & Conditions"
        }
      ]
    }
  ],
  sales_office_phone_number: "(406) 571-3006",
  sales_office_email_address: "sales@wagyulive.com",
  marketing_phone_number: "(406) 571-3006",
  marketing_email_address: "marketing@wagyulive.com",
  support_email_address: "support@wagyulive.com",
  facebook_url: "https://www.facebook.com/AngusLiveAuction",
  instagram_url: "https://www.instagram.com/angusliveg2/",
  twitter_url: "https://twitter.com/live_angus",
  youtube_url: "https://www.youtube.com/channel/UCZUxDN5gyn_MGwfG3CBupVg/featured"
}