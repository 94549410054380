
export const auctionCustom = /* GraphQL */ `
  query AuctionCustom($auctionId: ID!, $lotNextToken: ID) {
    auction(auctionId: $auctionId) {
      auctionId
      title
      startTime
      endTime
      locationType
      locationCity
      locationState
      description
      businessAccountId
      type
      closingExtensionEnabled
      closingExtensionStyle
      closingExtensionInterval
      status
      salesInfoBannerKeys
      salesInfoNotes
      sellerLocationTitle
      sellerContactName
      sellerAddress
      sellerCity
      sellerState
      sellerZip
      sellerEmail
      sellerPhoneNumbers {
        phone
        preferred
        mobile
      }
      saleOverview
      salesRepName
      salesRepContact
      salesRepEmail
      welcomeMessage
      bidIncrement
      startBid
      floorPrice
      catalogKey
      introVideoUrl
      paymentOptions
      shippingInstructions
      receivingInstructions
      liveBidLocation
      bannerImageKey
      hasCustomInvoiceDetails
      hasStandardTermsAndConditions
      hasCustomTermsAndConditions
      customTermsAndConditions
      hasStandardDeliveryAndPaymentTerms
      hasCustomDeliveryAndPaymentTerms
      customDeliveryAndPaymentTerms
      timezone
      useSellerContactInfo
      version
      lots(nextToken:$lotNextToken) {
        nextToken
        items {
          auctionId
          lotId
          title
          lotOrder
          biddingDisabled
          businessAccountId
          customFields {
            customLabel
            customValue
          }
          lotNum
          category
          interest
          embryoType
          donorName
          donorSire
          donorDam
          breed
          consigner
          specialTerms
          deliveryMethods
          startBid
          floorPrice
          interest
          quantity
          taxable
          tag
          regNum
          regUrl
          tattoo
          dob
          sexedCategory
          recipientId
          exportable
          footnote
          contactInfo
          pictures
          photos {
            storageName
            fileName
            caption
            cover
          }
          videos {
            storageName
            fileName
            caption
            cover
          }
          externalVideoUrl
          bidIncrement
          currentBid
          nextBid
          bannerImageKey
          version
          bidCount
          bidderId
          bidderCount
          bidderAccountId
          actualEPD
          importError
          importErrors
        }
      }
      primaryBusiness {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      businesses {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessTimezone
        version
      }
      bidders {
        nextToken
        items {
          auctionId
          bidderAccountId
          bidderFirstName
          bidderLastName
        }
      }
    }
  }
`;

export const auctionAllLotBidsCustom = /* GraphQL */ `
  query AuctionAllLotBidsCustom($auctionId: ID!, $lotNextToken: ID) {
    auction(auctionId: $auctionId) {
      auctionId
      lots(nextToken:$lotNextToken) {
        nextToken
        items {
            auctionId
            lotId
            lotNum
            lotOrder
            title
            tag
            regNum
            regUrl
            tattoo
            dob
            sexedCategory
            bidIncrement
            currentBid
            lastBidTime
            bidCount
            bidderId
            bidderCount
            bidderAccountId
        }
      }
    }
  }
`;

export const openAuctionsCustom = /* GraphQL */ `
  query OpenAuctionsCustom(
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    openAuctions(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        status
        salesInfoBannerKeys
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        timezone
        createdAt
        updatedAt
        version
        primaryBusiness {
          accountId
          businessName
          email
          familyName
          givenName
          providerName
          phone
          physicalAddress
          physicalCity
          physicalState
          physicalZip
          physicalCountry
          physicalCountryStateCity
          billingAddress
          billingCity
          billingState
          billingZip
          billingCountry
          termsOfService
          receiveInfo
          version
        }
        businesses {
          accountId
          businessName
          email
          familyName
          givenName
          providerName
          phone
          physicalAddress
          physicalCity
          physicalState
          physicalZip
          physicalCountry
          physicalCountryStateCity
          billingAddress
          billingCity
          billingState
          billingZip
          billingCountry
          termsOfService
          receiveInfo
          version
        }
      }
      nextToken
    }
  }
`;

export const allAuctionsCustom = /* GraphQL */ `
  query AllAuctionsCustom(
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctions(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        title
        startTime
        endTime
        locationType
        locationCity
        locationState
        description
        businessAccountId
        type
        status
        salesInfoBannerKeys
        sellerLocationTitle
        sellerContactName
        sellerAddress
        sellerCity
        sellerState
        sellerZip
        sellerEmail
        saleOverview
        salesRepName
        salesRepContact
        salesRepEmail
        welcomeMessage
        bidIncrement
        catalogKey
        introVideoUrl
        paymentOptions
        shippingInstructions
        receivingInstructions
        liveBidLocation
        bannerImageKey
        timezone
        createdAt
        updatedAt
        version
        primaryBusiness {
          accountId
          businessName
          email
          familyName
          givenName
          providerName
          phone
          physicalAddress
          physicalCity
          physicalState
          physicalZip
          physicalCountry
          physicalCountryStateCity
          billingAddress
          billingCity
          billingState
          billingZip
          billingCountry
          termsOfService
          receiveInfo
          version
        }
        businesses {
          accountId
          businessName
          email
          familyName
          givenName
          providerName
          phone
          physicalAddress
          physicalCity
          physicalState
          physicalZip
          physicalCountry
          physicalCountryStateCity
          billingAddress
          billingCity
          billingState
          billingZip
          billingCountry
          termsOfService
          receiveInfo
          version
        }
      }
      nextToken
    }
  }
`;

export const auctionLotCustom = /* GraphQL */ `
  query AuctionLotCustom($auctionId: ID!, $lotId: ID!) {
    auctionLot(auctionId: $auctionId, lotId: $lotId) {
      auctionId
      lotId
      title
      lotOrder
      lotNum
      biddingDisabled
      businessAccountId
      customFields {
        customLabel
        customValue
      }
      category
      interest
      embryoType
      donorName
      donorSire
      donorDam
      breed
      consigner
      specialTerms
      deliveryMethods
      startBid
      floorPrice
      quantity
      taxable
      tag
      regNum
      regUrl
      lineageHierarchy {
        sire
        sireRegNum
        sireUrl
        dam
        damRegNum
        damUrl
        sireParents {
          sire
          sireRegNum
          sireUrl
          dam
          damRegNum
          damUrl
        }
        damParents {
          sire
          sireRegNum
          sireUrl
          dam
          damRegNum
          damUrl
        }
      }
      epdMeasures {
        category
        measure
        value
        accuracy
        progeny
        percentage
        order
      }
      tattoo
      dob
      sexedCategory
      interest
      recipientId
      exportable
      footnote
      contactInfo
      pictures
      photos {
        storageName
        fileName
        caption
        cover
      }
      videos {
        storageName
        fileName
        caption
        cover
      }
      externalVideoUrl
      bidIncrement
      currentBid
      nextBid
      bannerImageKey
      version
      bidCount
      bidderAccountId
      bidderId
      bidderCount
      actualEPD
      importError
      importErrors
    }
  }
`;

export const auctionLotVersionCustom = /* GraphQL */ `
  query AuctionLotVersionCustom($auctionId: ID!, $lotId: ID!) {
    auctionLot(auctionId: $auctionId, lotId: $lotId) {
      version
    }
  }
`;

export const auctionLotCountsCustom = /* GraphQL */ `
  query AuctionLotCountsCustom($auctionId: ID!, $lotId: ID!) {
    auctionLot(auctionId: $auctionId, lotId: $lotId) {
      bidCount
      bidderCount
    }
  }
`;

export const auctionLotBidsCustom = /* GraphQL */ `
  query AuctionLotBidsCustom($auctionId: ID!, $lotId: ID!,$bidLimit:Int, $bidNextToken:ID) {
    auctionLot(auctionId: $auctionId, lotId: $lotId) {
      auctionId
      lotId
      bids(limit: $bidLimit, nextToken:$bidNextToken){
        nextToken
        items {
          auctionId
          lotId
          bidType
          bidderFirstName
          bidderLastName
          price
          bidTime
          bidderAccountId
          bidderId
        }
      }
    }
  }
`;

export const businessesCustom = /* GraphQL */ `
  query BusinessesCustom($limit: Int, $nextToken: ID) {
    businesses(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        businessName
        email
        familyName
        givenName
        nameTitle
        providerName
        phone
        physicalAddress
        physicalCity
        physicalState
        physicalZip
        physicalCountry
        physicalCountryStateCity
        billingAddress
        billingCity
        billingState
        billingZip
        billingCountry
        termsOfService
        receiveInfo
        businessWebSite
        businessNotes
        businessPhoneNumbers {
          phone
          preferred
          mobile
        }
        businessTimezone
        version
      }
      nextToken
      scannedCount
    }
  }
`;
export const updateAuctionLotPriceParmsCustom = /* GraphQL */ `
  mutation UpdateAuctionLotPriceParmsCustom(
    $auctionId: ID!
    $lotId: ID!
    $expectedVersion: Int!
    $input: UpdateAuctionLotInput!
  ) {
    updateAuctionLot(
      auctionId: $auctionId
      lotId: $lotId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      floorPrice
      bidIncrement
      nextBid
      version
    }
  }
`;

export const auctionBiddersCustom = /* GraphQL */ `
  query AuctionBiddersCustom($auctionId: ID!) {
    auction(auctionId: $auctionId) {
      auctionId
      version
      bidders {
        nextToken
        items {
          auctionId
          bidderAccountId
          bidderFirstName
          bidderLastName
        }
      }
    }
  }
`;

export const auctionLotsBidPriceCustom = /* GraphQL */ `
  query AuctionLotsBidPriceCustom(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionLotsBidPrice(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        lotId
        lotNum
        title
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        bidIncrement
        currentBid
        lastBidTime
        bidCount
        bidderId
        bidderCount
        bidderAccountId
      }
      nextToken
    }
  }
`;
export const auctionLotsBidTimeCustom = /* GraphQL */ `
  query AuctionLotsBidTimeCustom(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortDirection: SortDirection
  ) {
    auctionLotsBidTime(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auctionId
        lotId
        lotNum
        title
        tag
        regNum
        regUrl
        tattoo
        dob
        sexedCategory
        bidIncrement
        currentBid
        lastBidTime
        bidCount
        bidderId
        bidderCount
        bidderAccountId
      }
      nextToken
    }
  }
`;

export const updateLotNumberCustom = /* GraphQL */ `
  mutation UpdateAuctionLotNumCustom(
    $auctionId: ID!
    $lotId: ID!
    $expectedVersion: Int!
    $input: UpdateAuctionLotInput!
  ) {
    updateAuctionLot(
      auctionId: $auctionId
      lotId: $lotId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      lotNum
      version
    }
  }
`;


export const auctionReportCustom = /* GraphQL */ `
  query AuctionReportCustom(
    $auctionId: ID!
    $limit: Int
    $nextToken: ID
    $sortField: AuctionReportSortField
    $sortDirection: SortDirection
  ) {
    auctionReport(
      auctionId: $auctionId
      limit: $limit
      nextToken: $nextToken
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      status
      message
      auctionId
      auctionName
      auctionStartDate
      auctionClosingDate
      totalGrossSales
      totalBidderCount
      totalLotCount
      invoiceSentDate
      version
      payments {
        paymentDate
        amount
        fees
      }
      lineItems {
        lotId
        lotNum
        title
        businessAccountId
        winningBidderFirstName
        winningBidderLastName
        winningBidderAddress
        winningBidderPhoneNumber
        winningBidderEmail
        finalBid
        quantity
        interest
        finalPrice
        saleStatus
        paymentStatus
        invoiceId
        invoice {
          auctionId
          auctionName
          auctionStartDate
          invoiceId
          invoiceNumber
          invoiceDate
          invoiceSentDate
          paymentDueDate
          bannerImageKey
          buyerName
          buyerAddress
          buyerPhoneNumber
          buyerEmail
          saleAuctionName
          saleAuctionClosingDate
          sellerName
          sellerPhoneNumber
          sellerEmail
          sellerWebsite
          sellerAddress
          sellerContactName
          sellerCity
          sellerState
          sellerZip
          sellerNotes
          totalAmountPaid
          totalAmountDue
          datePaid
          paid
          paymentMethod
          paymentStatus
          paymentInvoiceId
          paymentCustomerId
          paymentInvoicePdf
          paymentUrl
        }
        sellerEmail
        sellerPhoneNumber
        sellerName
        additionalLineItems {
          amount
          note
        }
        version
      }
      nextToken
    }
  }
`;

export const updateUserEmailCustom = /* GraphQL */ `
  mutation UpdateUserEmailCustom($accountId: ID!, $newemail: AWSEmail!) {
    updateUserEmail(accountId: $accountId, newemail: $newemail) {
      accountId
      email
      version
    }
  }
`;

export const updateUserRolesCustom = /* GraphQL */ `
  mutation UpdateUserRolesCustom(
    $accountId: ID!
    $expectedVersion: Int!
    $input: UserContactDetailsInput
  ) {
    updateUserContactDetails(
      accountId: $accountId
      expectedVersion: $expectedVersion
      input: $input
    ) {
      userRoles
      version
    }
  }
`;

export const accountByEmailVersionCustom = /* GraphQL */ `
  query AccountByEmailVersionCustom($email: AWSEmail!) {
    accountByEmail(email: $email) {
      accountId
      version
    }
  }
`;
